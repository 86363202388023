.form-switch.form-switch-md {
    margin-bottom: 1rem;
  }
  
  .form-switch.form-switch-md .form-check-input {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
  }

  .required::after {
    content: ' *';
    color: red;
  }